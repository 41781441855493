import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import ContentDossierToolbar from '../ContentDossierToolbar/ContentDossierToolbar'
import { KeDoContext } from '../../../../kedo-context'

const DefaultHeader = ({
    dossier,
    defdossier,
    showBreadcrumb,
    onDeleteSuccess,
    subscribed,
    favorite,
}) => {
    const kedo = useContext(KeDoContext)
    let history = useHistory()
    const defaultQuery = defdossier?.settings?.query_settings?.default_query
        ? `?dossierQuery=${defdossier?.settings?.query_settings?.default_query}`
        : ''

    return (
        <div className="sticky dossier_info_header">
            <Row style={{ flexWrap: 'nowrap' }}>
                <Col className={'dossierInfo'}>
                    {showBreadcrumb !== false ? (
                        <Row className={'breadcrumbRow'}>
                            <Col xs={12}>
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        onClick={() => history.push('/')}
                                    >
                                        {kedo.t('Home')}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        onClick={() =>
                                            history.push(
                                                `/defdossier/${dossier.def_dossier_id}${defaultQuery}`
                                            )
                                        }
                                    >
                                        {kedo
                                            .env()
                                            .translateItem(
                                                defdossier,
                                                'defdossier',
                                                true
                                            )}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {dossier.summary}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    ) : null}
                    <h1>
                        {dossier && dossier.summary
                            ? dossier.summary
                            : dossier.id}
                    </h1>
                    <br />
                </Col>
                <Col className={'text-right'} >
                    <ContentDossierToolbar
                        onDeleteSuccess={onDeleteSuccess}
                        favorite={favorite}
                        subscribed={subscribed}
                        kedo={kedo}
                        id={dossier.id}
                        defdossier={defdossier}
                        dossier={dossier}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default DefaultHeader
